@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Rajdhani", sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
}
html.noscroll {
  overflow: hidden;
}

button {
  background: none;
  border: none;
  outline: none;
}

.App {
  width: 70%;
  margin: auto;
}
.App .my {
  width: 100%;
  position: relative;
  padding-top: 100px;
  min-height: 100vh;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
}
.App .my .my_name {
  font-size: 60px;
  font-weight: 700;
  color: rgb(176, 75, 255);
  margin: 15px 0;
}
.App .my .my_prof {
  font-size: 50px;
  font-weight: 700;
  color: #6a6969;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
}
.App .my .my_desc {
  margin: 50px 0 80px 0;
  font-weight: 500;
}
.App .my .my_desc span {
  color: rgb(176, 75, 255);
  font-size: 18px;
  font-weight: 600;
}
.App .my .my_desc a {
  text-decoration: none;
}
.App .title {
  font-size: 30px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}
.App .title p {
  margin-right: 20px;
}
.App .title .line {
  background: black;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}
.App #experience {
  padding-top: 100px;
}
.App #experience span {
  color: rgb(176, 75, 255);
  font-size: 18px;
  font-weight: 600;
}
.App #experience a {
  text-decoration: none;
}
.App .side_link {
  position: absolute;
  right: 50px;
  bottom: 0;
  position: fixed;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.App .side_link a {
  text-decoration: none;
  writing-mode: vertical-rl;
  margin-bottom: 15px;
  color: rgb(176, 75, 255);
  transition: 0.5s;
}
.App .side_link a:hover {
  transform: translateY(-8px);
}
.App .side_line {
  background: black;
  height: 150px;
  width: 3px;
}
.App #projects {
  padding-top: 100px;
}
.App .social {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  position: fixed;
  left: 50px;
  bottom: 0;
}
.App .social .social_links {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.App .social .social_links img {
  width: 20px;
  margin-top: 15px;
  transition: 0.3s;
}
.App .social .social_links img:hover {
  transform: scale(1.2);
}
.App #contact {
  text-align: center;
  width: 50%;
  margin: auto;
  padding: 100px 0 150px 0;
}
.App #contact .end_title {
  font-size: 30px;
  font-weight: 700;
  margin: 50px;
}

.button {
  color: black;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid rgb(176, 75, 255);
  border-radius: 5px;
  background: #baffaf;
  padding: 15px 25px;
  display: inline-flex;
}

@media screen and (max-width: 800px) {
  .App {
    width: 80%;
  }
  .App .title .line {
    width: 200px;
  }
  .App .my .my_name {
    font-size: 50px;
  }
  .App .my .my_prof {
    font-size: 45px;
  }
  .App .side_link,
  .App .side_line {
    display: none !important;
  }
  .App #contact {
    padding-bottom: 50px;
    width: 70%;
  }
  .App .social {
    position: static;
    padding-bottom: 80px;
  }
  .App .social .social_links {
    flex-direction: row;
  }
  .App .social .social_links img {
    width: 25px;
    margin: 20px;
  }
}
@media screen and (max-width: 500px) {
  .App {
    width: 90%;
  }
  .App .my .my_name {
    font-size: 35px;
  }
  .App .my .my_prof {
    font-size: 30px;
  }
  .App .title {
    font-size: 20px;
  }
  .App #contact .end_title {
    font-size: 25px;
  }
}
#menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 6;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  transition: 0.3s;
  padding: 0 100px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
#menu #svg path {
  stroke-dasharray: 150;
  animation: write 3s forwards;
}
@keyframes write {
  from {
    stroke-dashoffset: 150;
    fill: white;
  }
  to {
    stroke-dashoffset: 0;
    fill: rgb(176, 75, 255);
  }
}
#menu div {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
#menu div a {
  margin: 0 15px;
  text-decoration: none;
}
#menu div a button {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.2px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
}
#menu div a button span {
  color: rgb(176, 75, 255);
  margin-right: 5px;
}
#menu div a button:hover {
  color: rgb(176, 75, 255);
}
#menu #toggle-butt {
  width: 40px;
  height: 22px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}
#menu #toggle-butt span {
  width: 100%;
  display: block;
  height: 3px;
  border-radius: 5px;
  background: rgb(176, 75, 255);
  transition: 0.1s;
}
#menu #toggle-butt span:nth-child(2) {
  width: 80%;
}
#menu #toggle-butt span:nth-child(3) {
  width: 60%;
}
#menu #toggle-butt.toggle span {
  width: 100%;
}
#menu #toggle-butt.toggle span:nth-child(1) {
  transform: translateY(9.5px) rotate(45deg);
}
#menu #toggle-butt.toggle span:nth-child(2) {
  opacity: 0;
}
#menu #toggle-butt.toggle span:nth-child(3) {
  transform: translateY(-9.5px) rotate(-45deg);
}

#sideMenu {
  transform: translateX(100vw);
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100vh;
  z-index: 5;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: 0.3s;
}
#sideMenu.show {
  transform: translateX(0);
}
#sideMenu a {
  margin: 0 15px;
  text-decoration: none;
}
#sideMenu a button {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.2px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  margin: 12px;
}
#sideMenu a button span {
  color: rgb(176, 75, 255);
  margin-right: 5px;
}
#sideMenu a button:hover {
  color: rgb(176, 75, 255);
}

@media screen and (max-width: 800px) {
  #menu {
    padding: 0 32px;
  }
}/*# sourceMappingURL=App.css.map */